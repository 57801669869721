<template>
  <v-container>
    <v-card-text>
      <v-text-field
        :label="$t('user.fields.firstName')"
        name="firstname"
        prepend-icon="mdi-account-box-outline"
        type="text"
        v-model="user.firstName"
        v-on:change="updateEvent()"
        :rules="stringRules"
      ></v-text-field>
    </v-card-text>

    <v-card-text>
      <v-text-field
        :label="$t('user.fields.lastName')"
        name="lastname"
        prepend-icon="mdi-account-box-outline"
        type="text"
        v-model="user.lastName"
        v-on:change="updateEvent()"
        :rules="stringRules"
      ></v-text-field>
    </v-card-text>

    <v-card-text>
      <v-text-field
        :label="$t('user.fields.email')"
        name="email"
        prepend-icon="mdi-at"
        type="text"
        v-model="user.email"
        v-on:change="updateEvent()"
        :rules="stringRules"
      ></v-text-field>
    </v-card-text>

    <v-card-text>
      <phone-number-input v-model="user.phoneNumber" :validate="true" />
    </v-card-text>

    <v-card-text>
      <v-text-field
        :label="$t('user.fields.password')"
        name="password"
        prepend-icon="mdi-textbox-password"
        type="password"
        v-model="user.password"
        v-on:change="updateEvent()"
        :rules="passwordRules"
      ></v-text-field>
    </v-card-text>
  </v-container>
</template>

<script>
export default {
  name: "UserRegistrationInputs",
  props: ["eUser"],
  data() {
    "".incl;
    return {
      valid: false,

      user: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
      },

      stringRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) => (v && v.length <= 255) || this.$t("validation.toLong"),
      ],

      phoneNumberRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) =>
          (v !== undefined && v[0] !== "0") ||
          this.$t("validation.contryCodeError"),
      ],

      passwordRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) => (v && v.length > 6) || this.$t("validation.passwordLength"),
        (v) =>
          (v && this.upperAndLowerCase(v)) ||
          this.$t("validation.passwordUpperAndLower"),
      ],
    };
  },

  created() {
    if (this.eUser !== undefined) {
      this.user.userId = this.eUser.userId;
      this.user.firstName = this.eUser.firstname;
      this.user.lastName = this.eUser.lastname;
      this.user.phoneNumber = this.eUser.phonenumber;
      this.user.email = this.eUser.email;
      this.user.password = this.eUser.password;

      this.updateEvent();
    }
  },

  methods: {
    upperAndLowerCase(val) {
      const upper = /[A-Z]/.test(val);
      const lower = /[a-z]/.test(val);

      return upper && lower;
    },
    updateEvent() {
      this.$emit("update-user", this.user);
    },
  },
};
</script>

<style></style>
